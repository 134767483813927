.top__nav {
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 999;
  background: var(--primary-color);
  width: calc(100%);
  height: 60px;
  line-height: 60px;
background-color: var(--primary-color);
box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);

/* background-color: #F4F7FE; */
}

.top__nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.navleft{
display: flex;
align-items: center;
column-gap: 8rem;
}

.top__nav-right {
  float: right;
  display: flex;
  align-items: center;
  column-gap: 2rem;
  justify-content: flex-end;
}

.Topnavsearch__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  /* background: #FFFFFF !important; */
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  
}

.search__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background: #FFFFFF !important;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  
}

.search__box input {
  width: 300px;
  background: transparent;
  border: none;
  outline: none;
  color: var(--small-text-color);
}

.search__box input::placeholder {
  color: var(--small-text-color);
}

.search__box span i {
  color: var(--small-text-color);
}

.profile img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border-width: 2px;
  border: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  object-fit: cover;
}

.dashleft-logo img {
  width: 70px;
  height: 35px;
  border-radius: 1%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  object-fit: cover;
  color: white;
  filter: brightness(0) invert(1);
}

.notification i {
  color: var(--small-text-color);
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 5px;
  color: white;
}

.notification {
  /* padding-top: 5px; */
  position: relative;
}

.badge {
  position: absolute;
  top: 25%;
  right: -15%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(201, 0, 0);
  border-radius: 50%;
  color: white ;
  font-size: 0.8rem;
  padding: 8px;
  cursor: pointer;
}


/* ////////////////// */

.notification__popup {
  position: absolute;
  right: 20px;
  top: 60px;
  width: 400px;
  max-height: 90vh;
  overflow-y: auto; /* Makes the popup scrollable */
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 15px;
  border-radius: 5px;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.notification__list {
  /* max-height: 550px; */
  overflow-y: auto; /* Scrollable area */
}

.notification__item {
  padding: 10px;
  border-radius: 2%;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.msg{
font-size: small;
margin: 0px;
padding: 0px;
}

.notification__item small{
font-size: 0.6rem;
}
.notification__item.unread {
  background-color: #f5f5f5;
}

.notification__item.read {
  background-color: #fff;
}
.notification__item.read:hover {
  background-color: #e3f0ff;
}

.mark-as-read-btn {
  height: 25px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 3px;
}

.mark-as-read-btn:hover {
  background-color: #0056b3;
}
.msgbottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nonotific{
  color: red;
  font-size: small;
}

