/* Container */
.dealerdtls-requests {
  min-height: calc(100vh - 80px);
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .dealerdtls-requests__heading {
    text-align: start;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  /* Table Styling */
  .dealerdtls-requests__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    /* table-layout: fixed; */
  }
  
  .dealerdtls-requests__thead-row {
    background-color: var(--primary-color);
    color: white;
  }
  
  .dealerdtls-requests__thead-cell {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;

    word-wrap: break-word;
    white-space: normal; 
  }
  
  .dealerdtls-requests__tbody-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  

  .dealerdtls-requests__tbody-row:hover {
    background-color: #dbdbdb;
  }

  .dealerdtls-requests__tbody-cell {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #495057;

    max-width: 200px; 
    overflow-wrap: break-word; 
  }
  
  /* Profile Image Styling */
  .dealerdtls-requests__profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .dealerdtls-requests__proof-image {
    width: 50px;
    height: 40px;
    object-fit: cover;
  }
  
  /* Action Buttons */
  .dealerdtls-requests__action-btn {
    padding: 6px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .view-btn {
    background-color: #28a745;
    color: white;
  }
  
  /* .reject-btn {
    background-color: #ffc107;
    color: white;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  } */
  
  /* Hover Effects */
  .dealerdtls-requests__action-btn:hover {
    opacity: 0.8;
  }

  .topflex{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

/* //////////////////////////////////////////////////// */

  .add-dealer-button {
    height: 35px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: var(--primary-color); /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-dealer-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.button-text {
    margin-right: 8px; /* Space between text and icon */
}

.button-icon {
    font-size: 16px; /* Adjust the size of the icon */
}



/* Add this to your CSS file */

.dealer-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* src/components/DealerForm.css */

.dealer-form-popup {

  background-color: #ffffff; /* White background for the popup */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 40px; /* Inner padding */
  margin: auto; /* Center the popup */
  position: relative; /* Allow for positioning of close button */
}

.dealer-form-popup h2 {
  font-size: 20px;
  text-align: start; /* Center the title */
  color: #333; /* Dark text color */
  margin-bottom: 30px;
}

.dealer-form-popup label {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  margin-bottom: 15px; /* Space between input fields */
  color:var(--primary-color);
}

.dealer-form-popup input[type="text"],
.dealer-form-popup input[type="file"] {
  flex: 1; /* Make the input fields take available space */
  padding: 10px; /* Inner padding */
  margin-left: 10px; /* Space between label and input */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 14px; /* Font size */
}

.dealer-form-popup input[type="radio"] {
  margin-left: 10px; 
  margin-right: 10px;
}

.dealer-form-popup button {
  background-color: rgb(0, 80, 0);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.dealer-form-popup button[type="button"] {
  background-color: #6c757d; /* Secondary button color */
}

.dealer-form-popup button:hover {
  opacity: 0.9; /* Slightly reduce opacity on hover */
}

.dealer-form-foot{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.custom-subtitle {
  font-size: 14px;
  color: #666; /* Adjust color as needed */
  margin-top: 5px; /* Space between title and subtitle */
}
