.allveh {
    margin-top: 70px;
    padding: 0px 10px;
    padding-bottom: 10px;
  }
  
 .allveh__wrapper{
  min-height: calc(100vh - 80px);
    padding: 20px;
    background-color: white;
 } 

  .allveh__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
padding-top: 10px;  
}

  .allveh__title {
    font-size: 20px;
    color: #000000;
    margin-bottom: 2rem;
  }
  
  .allvehfilter__widget-wrapper {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
  }
  
  .allvehfilter__widget-01 select,
  .allvehfilter__widget-02 select {
    border: none;
    padding: 7px 20px;
    border-radius: 50px;
    background: var(--primary-color);
    color: #fff;
    cursor: pointer;
    margin-bottom: 2rem;
  }

  .allveh__car-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 0px;
  }
  
  .vehimgdiv {
    text-align: center;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .allveh__car-list .vehimgdiv:hover {
    transform: scale(1.05);
  }
  
  .allvehcar-photo {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
    
  }
  
  .allvehcar-name {
    font-size: .8rem;
    font-weight: bold;
    color: #333;
  }
  
  .vehnamediv{
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
  }
  .vehnamediv span{
    cursor: pointer;
    font-size: 0.8rem;
   color: rgb(0, 71, 165);
  }