
.Homepage-Main{
  height: 100vh;
  min-width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0px;  
}

.sea_taxi_div_pic,.sea_taxi_sub_conts{
    width: 100%;

  
}
.sea_taxi_div_pic img{
    width: 100%;
    
}

.sea_taxi_main_div{
  margin: 0;
  padding: 0;
  background-size: cover;
  height: 100vh;
  display: flex;
  background-color: #F5F7FB;
  margin-top: 50px;
  min-width: 100%;
  overflow: hidden;
}

.sea_taxi_amin_div_1{
    display: flex;
    gap: 20px;
  width: 100%;
    justify-content: center;
    align-items: center;
}
.sea_taxi_sub_conts{
  max-width: 50%;
  padding: 50px;
  margin-left: 100px;
}

.sea_taxi_sub_conts h1{
    font-size: 60px;
    color: black;
   font-weight: bolder;
}
.sea_taxi_sub_conts h1 span{
    font-size: 55px;
    color: #0174D3;
    text-decoration: underline;
   font-weight: bolder;
}
.sea_taxi_sub_conts p{
    font-size: 18px;
    color: rgba(0, 0, 0, 0.459);
    margin-top: 20px;
}
.sra_txi_hed_btn,.sea_taxi_card_bts{
    text-align: center;
    margin-top: 20px;
}
.sra_txi_hed_btn button,.sea_taxi_card_bts button{
    padding: 10px 30px;
    border: none;
    background-color: #0174D3;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
}

.sra_txi_hed_btn button:hover{
  border-radius: 25px;
  background-color: #003866;
}


.home_taxi_app_download {
  margin-top: 40px;
  display: flex;
  justify-content: start; 
  gap: 15px; 
}

.home_taxi_app_download_1 img {
  width: 150px; 
  height: 50px;
  object-fit: cover;
}

.sea_taxi_sub_add_logo img{
    width: 50%;
}
.sea_taxi_sub_div{
    background-color: white;
}
.sea_taxi_sub_div_1{
    display: flex;
    gap: 20px;
}

.sea_mbl_pic_add,.sea_taxi_sub_add_logo{
  width: 100%;
}
.sea_mbl_pic_add img{
  width: 100%;
}
.sea_taxi_sub_add_logo,.sea_taxi_sub_card_div{
    width: 100%;
}
.sea_taxi_sub_add_logo{
    padding: 20px;
}
.sea_taxi_sub_card_div{
    padding: 50px;
}
.sea_taxi_sub_card_div_1{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 70%;
    height: auto;
    margin: auto;
    border-radius: 20px;
    background-color: #fff;
}
.sea_taxi_cards_conts{
    display: grid;
    gap: 20px;
    padding: 10px;

}
.sea_taxi_sub_logo_conts p{
    color: #0174D3;
    font-size: 15px;
    text-align: justify;
    line-height: 1.9;
}
.sea_texi_hds h2{
    text-align: center;
   color: #fff;
}
.sea_texi_hds{
    padding: 10px;
    background-color: #0174D3;
    border-radius: 20px 20px 0px 0px;
}
.sea_taxi_cards_conts input{
    padding: 10px;
    border: none;
    border-bottom: 1px solid #333;
}


.sea_taxi_add_car{
    background-color: #0174D3;
    z-index: 1000;
    min-width: 100%;
}

.sea_taxi_add_car_div {

    text-align: center;
    color: rgba(255, 255, 255, 0.822);

}
.title_nearloc{
  padding: 30px 30px 5px 30px;
}
.sea_taxi_car_view_btn {
    position: relative;
    left: 30%;
}
.sea_taxi_car_view_btn button{
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid #fff;
    background-color: #0174D3;
    margin: 15px;
    color: #fff;
    cursor: pointer;
}
.sea_taxi_add_car_main_div{
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}
.sea_taxi_card_view_div {
  position: relative; /* Ensures proper positioning for the pseudo-element */
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
              0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden; /* Prevents ::before content from spilling out */
}

.sea_taxi_card_view_div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  background-size: 100% 0%;
  background-repeat: no-repeat;
  z-index: 1; /* Ensure it appears above the content */
  transition: background-size 0.8s ease;
}

.sea_taxi_card_view_div:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2),
              0 12px 30px rgba(0, 0, 0, 0.2);
}

.sea_taxi_card_view_div:hover::before {
  background-size: 100% 100%;
}

.sea_taxi_card_view_div img {
  width: 100%;
  height: 200px; /* Maintain image aspect ratio */
  border-radius: 20px;
  object-fit: cover; /* Ensures the image fills the area neatly */
  display: block; /* Prevents inline space issues */
  z-index: 0; /* Ensure the image is below the pseudo-element */
}

.description {
  font-size: 15px;  /* Smaller font size */
  color: #cecece;
  padding-bottom: 10px;
}

.sea_taxi_car_details_view{
   background-color: #F5F7FB;
   padding: 30px;
}

.sea_taxi_car_details_view_1{
    text-align: center;
    color: rgba(0, 0, 0, 0.808);
    padding-top: 30px;
}

.sea_taxi_details_view_div_1{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 20px;
    transition: transform 0.2s, box-shadow 0.2s; 
    
}

.sea_taxi_details_view_div_1:hover{
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2),
              0 12px 30px rgba(0, 0, 0, 0.2); 
}

.sea_taxi_details_view_div{
    display: flex;
    gap: 50px;
    padding: 20px;
}
.sea_taxi_details_view_div_1 h2{
  font-size: 20px;
  color: rgba(0, 0, 0, 0.842);
}
.sea_taxi_crd_pic{
    width: 100%;
   
}
.sea_taxi_crd_pic img{
  border-radius: 20px;
    width: 100%;
    /* position: relative;
    top: -94px;
    left: 10%; */
}

.sea_taxi_details_view{
    padding: 20px;
}
.sea_taxi_details_view_div_1 svg{
   width: 20px;
}

.card_taxi_text_p{
  font-size: 15px;
  padding: 10px 0px;
}
/* Flexbox Container */
.flex-container {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    gap: 8px; /* Space between the SVG and the text */
    font-size: 16px; /* Adjust as needed */
    color: #01559b;
  }
  .sea_taxi_sub_seting_div{
    padding: 20px;
    background-color: #01559b;
  }
  .sea_taxi_sub_seting_div_1 h2{
    text-align: center;
    padding: 30px;
    color: rgba(255, 255, 255, 0.664);
  }
  .sea_taxi_sub_seting_div_conts{
    display: flex;
    gap: 20px;
    padding: 50px;
  }
  .sea_taxi_sub_seting_div_conts_1{
    display: flex;
    align-items: center; /* Align icon and content vertically in the center */
    gap: 16px; /* Space between the icon and the text */
    padding: 16px;
   width: 100%;
   background-color: #F5F7FB;
   border-radius: 20px;
  }
  .sea_taxi_sub_seting_div_conts_1 h2{
    font-size: 20px;
  }
 
  .sea_txic_icon_pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    background-color: #01559b; /* White background */
    border-radius: 50%; /* Makes the background circular */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .sea_txic_icon_pic svg {
    width: 80%;  /* Make SVG take up full width */
    height: 100%; /* Make SVG take up full height */
    fill: currentColor; /* Follows the text color */
    color: #fff;
  }
  .sea_txic_icon_conts {
    display: flex;
    flex-direction: column; /* Stack h2 and p vertically */
  }
  
  .sea_txic_icon_conts h2 {
    margin: 0;
    font-size: 20px; /* Adjust font size as needed */
    color: #333; /* Adjust text color */
  }
  
  .sea_txic_icon_conts p {
    margin-top: 8px;
    font-size: 14px; /* Adjust font size */
    color: #666; /* Adjust text color */
    line-height: 1.5;
  }
.sea_taxi_mobile_app{
  padding: 20px 0px;
}

  .sea_taxi_mobile_app h2{
    text-align: center;
    padding: 30px;
    color: #333;
  }
  .sea_taxi_app_download{
    margin-top: 40px;
    display: flex;
    justify-content: start;
    gap: 15px;
  }
.sea_taxi_app_download_1 img{

  width: 150px;
    height: 50px;
    object-fit: cover;
}
.sea_taxi_mobile_app_1{
    display: flex;
    align-items: center;
}
.app_pic_add,.sea_taxi_mobile_app_conts{
    width: 100%;
}
.sea_taxi_mobile_app_conts{
    padding: 50px;
}
.sea_taxi_mobile_app_conts h1{
    font-size: 25px;
}
.sea_taxi_mobile_app_conts p{
    text-align: justify;
    font-size: 15px;
    line-height: 1.9;
    padding-top : 20px;
  
}


/* //////////////////////////////////////////// */

.book_steps_div{
 height: 90vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 background-color: #F5F7FB;
 overflow: hidden;
}

.book_steps_div h4{
 color: rgba(0, 0, 0, 0.507);
}
.book_steps_div h3{
  margin-top: 10px;
 color: black;
 font-size: 30px;
 font-weight: bold;
}


.card_row_div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10%;
}
.single_card{
min-width: 230px !important;
 margin-top: 100px;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 100px;

}
.card_icon_div:hover{
  transform: translateY(-5px); /* Lift the card slightly on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), /* Stronger shadow on hover */
              0 12px 30px rgba(0, 0, 0, 0.2);
}
.card_icon_div{
  height: 150px;
  width: 150px;
  border-radius: 20px;
  background-color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #01559b;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), /* Stronger shadow on hover */
  0 12px 30px rgba(0, 0, 0, 0.2);
}
.single_card h4{
 color: black;
 margin-top: 15px;
 padding: 10px;
 font-size: 18px;
 font-weight: bolder;
}
.single_card h5{
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

/* styles.css */
.dotted-line {
  border-top: 2px dashed  #0000002f; /* Black dotted line */
  width: 100%; /* Full width */

}



.sea_taxi_chooseus{
  background-color: white;
}
.sea_taxi_chooseus2{
  display: flex;
  gap: 20px;
}

.sea_taxi_chooseus_picbg {
  width: 50%;
}
.sea_taxi_chooseus_picbg img{
  width: 100%;
}


.sea_taxi_chooseus_cont{
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.choose_heading{
  margin-top: 50px;
  color: rgba(0, 0, 0, 0.507);;
}
.sea_taxi_chooseus_cont h3{
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.788);
  font-size: 30px;
  font-weight: bolder;
}

.listtile {
  display: flex;
  background-color: #f9f9f9;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #dddddd3b;
  border-radius: 8px;
  justify-content: start;
  align-items: center;
}
.listtile i{
 font-size: 35px;
 margin-right: 20px;
 color: #01559b;
 padding: 15px;
 background-color: white;
 border-radius: 10px;
}

.listtile h4{
  font-size: 18px;
  margin-bottom: 10px;
}

.listtile p{
  font-size: 14px;
  color: #777;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0px 20px;
  width: 85%;
  margin-top: 20px;
  padding-bottom: 50px;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px; /* Ensures the pseudo-element matches the item's shape */
}

.grid-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px; /* Matches the image's border-radius */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  opacity: 0; /* Start with no overlay */
  transition: opacity 0.3s ease; /* Smooth transition for hover effect */
  z-index: 1; /* Ensures it overlays the image */
}

.grid-item:hover::before {
  opacity: 1; /* Show overlay on hover */
}

.grid-item img:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2),
              0 12px 30px rgba(0, 0, 0, 0.2);
}

/* Ensures the text-overlay is above the pseudo-element */
.text-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  z-index: 2;
}

.text-overlay h3 {
  margin: 0;
  font-size: 18px;
}

.text-overlay p {
  margin: 0;
  font-size: 14px;
}


/* Existing styles here... */

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0px 20px;
  width: 85%;
  margin-top: 20px;
  padding-bottom: 50px;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.grid-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.grid-item:hover::before {
  opacity: 1;
}

.grid-item img:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2),
              0 12px 30px rgba(0, 0, 0, 0.2);
}

.text-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  z-index: 2;
}

.text-overlay h3 {
  margin: 0;
  font-size: 18px;
}

.text-overlay p {
  margin: 0;
  font-size: 14px;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    gap: 15px;
    padding: 0px 10px;
    width: 100%;
  }

  .grid-item img {
    height: 200px; /* Adjust image height for smaller screens */
  }

  .text-overlay h3 {
    font-size: 16px; /* Reduce font size */
  }

  .text-overlay p {
    font-size: 12px; /* Reduce font size */
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr); /* Single column for mobile */
    gap: 10px;
  }

  .grid-item img {
    height: 150px; /* Further adjust image height */
  }

  .text-overlay h3 {
    font-size: 14px; /* Further reduce font size */
  }

  .text-overlay p {
    font-size: 10px; /* Further reduce font size */
  }
}

@media (max-width: 768px) {
  .listtile-container {
    flex-direction: column;
    align-items: center;

  }

  .listtile {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* .sea_taxi_chooseus_cont p{
  color: #0174D3;
  font-size: 15px;
  text-align: justify;
  line-height: 1.9;
} */






@media screen and (max-width: 768px) {

  .sea_taxi_main_div{
    padding-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F7FB;
    overflow: hidden;
  }

  
    .sea_taxi_amin_div_1 {
        flex-direction: column; /* Stack content vertically */
        text-align: center; 
        justify-content: center;
        align-items: center;
      }
    
      .sea_taxi_sub_conts h2 {
        font-size: 32px; /* Reduce font size */
        margin-top: 15px;
      }
    
      .sea_taxi_sub_conts p {
        font-size: 18px; /* Reduce font size */
      }
    
      .sea_taxi_div_pic {
        width: 100%;
      }
      .sea_taxi_div_pic img {
        width: 100%;
        overflow: hidden;
        object-fit: cover;
      }
    
      /* Button Styling for Mobile */
      .sra_txi_hed_btn button {
        /* width: 100%;  */
        font-size: 18px; /* Reduce font size */
        padding: 10px 25px; /* Adjust padding */
        margin-top: 10px;
      }
      .sea_taxi_sub_conts {
        max-width: 50%;
        padding: 0px;
        margin-left: 10px;
       }

    .sea_taxi_sub_div_1 {
        flex-direction: column; /* Stack content vertically */
        text-align: center; /* Center-align content */
        margin-top: 15px;
      }
    
      /* .sea_taxi_sub_add_logo img {
        width: 80%; 
        margin: auto; 
      } */
    
      .sea_taxi_sub_card_div {
        padding: 0px; /* Adjust padding for mobile */
      }
      .sea_taxi_sub_add_logo {
        padding: 0px;
    }
    
      .sea_taxi_sub_card_div_1 {
        width: 90%; /* Increase card width for mobile */
      }
    
      .sea_taxi_cards_conts input {
        padding: 8px; /* Adjust input padding */
        margin-bottom: 10px; /* Adjust spacing between inputs */
      }
    
      .sea_taxi_card_bts button {
        width: 100%; /* Full-width button on mobile */
        padding: 10px 0;
        font-size: 18px; /* Adjust font size */
      }

      .sea_taxi_add_car_div {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    
      .sea_taxi_add_car_div h2 {
        margin-left: 0; /* Remove left margin for mobile */
        font-size: 24px; /* Adjust font size for smaller screens */
      }
    
      .sea_taxi_car_view_btn {
        position: relative;
        left: 0; /* Reset position for mobile */
        margin-top: 15px; /* Add space between button and heading */
      }
    
      .sea_taxi_add_car_main_div {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
     
    
      .sea_taxi_card_view_div img {
        width: 100%; /* Set images to take full width */
        height: auto; /* Maintain aspect ratio */
      }
    
      .sea_taxi_car_view_btn button {
        padding: 8px 20px; /* Adjust button padding */
        font-size: 16px; /* Adjust font size for better readability */
      }
      .sea_taxi_details_view_div {
        flex-direction: column; /* Stack items vertically */
        gap: 15px; /* Adjust gap for mobile view */
        padding: 15px; /* Reduce padding for mobile */
      }
    
      .sea_taxi_details_view_div_1 {
        padding: 15px; /* Adjust inner padding */
        text-align: center; /* Center-align text */
        margin: 0 auto; /* Center each item */
      }
    
      .sea_taxi_crd_pic img {
        width: 60%; /* Reduce image width */
        top: -50px; /* Adjust positioning */
        left: 20%; /* Center image */
      }
    
      .sea_taxi_details_view {
        padding: 10px; /* Reduce container padding */
      }
    
      .sea_taxi_details_view_div_1 h2 {
        font-size: 18px; /* Adjust heading size */
        margin-top: 10px; /* Add spacing */
      }
    
      .sea_taxi_details_view_div_1 p {
        font-size: 14px; /* Adjust paragraph font size */
      }
    
      .flex-container {
        font-size: 14px; /* Smaller font size for mobile */
        justify-content: center; /* Center the flex items */
      }
      .sea_taxi_sub_seting_div_conts {
        flex-direction: column; /* Stack each section vertically */
        padding: 20px; /* Adjust padding for smaller screens */
      }
    
      .sea_taxi_sub_seting_div_conts_1 {
        flex-direction: column; /* Stack the icon and content vertically */
        align-items: center; /* Center-align the items */
        gap: 12px; /* Reduce the gap between the icon and text */
        padding: 0px; /* Reduce padding for smaller screens */
      }
    
      .sea_txic_icon_pic {
        width: 50px; /* Adjust the size of the icon */
        height: 50px; /* Adjust the size of the icon */
      }
    
      .sea_txic_icon_conts h2 {
        font-size: 18px; /* Slightly reduce the heading size */
        text-align: center; /* Center-align heading text */
      }
    
      .sea_txic_icon_conts p {
        text-align: center; /* Center-align paragraph text */
        font-size: 14px; /* Adjust font size for readability */
        margin-top: 0px;
      }
    
      .sea_taxi_sub_seting_div_1 h2 {
        font-size: 24px; /* Adjust title size for mobile view */
        padding: 20px; /* Adjust padding */
      }
      .sea_taxi_mobile_app_1 {
        flex-direction: column; /* Stack the content and image vertically */
      }
    
      .sea_taxi_mobile_app_conts {
        padding: 0px; /* Reduce padding for smaller screens */
      }
    
      .sea_taxi_mobile_app_conts h1 {
        font-size: 22px; /* Adjust the heading size */
        text-align: center; /* Center-align the heading */
      }
    
      .sea_taxi_mobile_app_conts p {
        text-align: center; /* Center-align the paragraph text */
        font-size: 14px; /* Adjust font size for readability */
        line-height: 1.6; /* Slightly reduce line height */
      }
    
      .sea_taxi_app_download {
        justify-content: center; /* Center the download buttons */
        gap: 12px; /* Add some spacing between buttons */
        padding: 20px ; /* Add padding above and below */
      }
    
      .sea_taxi_app_download_1 img {
        width: 100%; /* Reduce image size for mobile view */
      }
    
      .app_pic_add {
        display: flex; /* Use flex for alignment */
        justify-content: center; /* Center-align the image */
        margin-top: 20px; /* Add some spacing */
      }
    
      .app_pic_add img {
        width: 80%; /* Adjust the width of the app image */
      }
    
      .sea_taxi_mobile_app h2 {
        font-size: 24px; /* Adjust the main title size */
        margin-top: 20px; /* Add some margin to the top */
      }


      .book_steps_div{
        height: auto;
       padding: 20px;
      }
      .card_row_div{
        width: 100%;
        height: auto;
        flex-direction: column;
      }
      .book_steps_div h3{
        margin-top: 10px;
       color: black;
       font-size: 20px;
       font-weight: bold;
      }
      .single_card{
        min-width: 230px !important;
       margin-top: 10px;
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: 10px;
      
      }
      .sea_taxi_chooseus_picbg {
        width: 100%;
      }
      .sea_taxi_chooseus_cont{

        text-align: center;
      }
      .sea_taxi_chooseus2{
        padding: 20px; 
        display: flex;
        flex-direction: column;  
      }

    }
    





    /* Mobile View CSS (Screens below 480px) */
    @media screen and (max-width: 480px) {

      .sea_taxi_sub_conts h1{
        font-size: 30px;
    }
    .sea_taxi_sub_conts h1 span{
      font-size: 25px;
  }
  .sea_taxi_sub_conts p{
    font-size: 12px;
}
.home_taxi_app_download {
  margin-top: 10px;
  gap: 5px; 
}
.home_taxi_app_download_1 img {
  width: 100px; 
  height: 30px;
}

      .sea_taxi_sub_conts h2 {
        font-size: 24px; /* Further reduce font size */
      }
    
      .sea_taxi_sub_conts p {
        font-size: 16px; /* Smaller text size */
      }
    
      /* .sra_txi_hed_btn button {
        padding: 8px 0; 
      } */
    
      .sea_taxi_div_pic img {
        width: 100%; /* Further reduce image width */
        margin-top: 50px;
      }
   
      .sea_taxi_sub_logo_conts p {
        font-size: 14px; /* Reduce text size */
        line-height: 1.6; /* Adjust line height */
      }
    
      .sea_taxi_sub_card_div_1 {
        width: 100%; /* Full-width card for small screens */
      }
    
      .sea_texi_hds h2 {
        font-size: 20px; /* Adjust heading font size */
      }
      .sea_taxi_add_car_div h2 {
        font-size: 20px; /* Further reduce heading font size */
      }
    
      .sea_taxi_car_view_btn button {
        width: 100%; /* Full-width button */
        font-size: 14px; /* Smaller font for very small screens */
        margin: 10px 0; /* Adjust margin for better spacing */
      }
    
      .sea_taxi_add_car_main_div {
        gap: 10px; /* Narrow gap between items */
      }
    
      .sea_taxi_card_view_div img {
        width: 100%; /* Full-width images */
      }
      .sea_taxi_details_view_div {
        gap: 65px; /* Further reduce gap */
        padding: 10px; /* Reduce padding */
      }
    
      .sea_taxi_details_view_div_1 {
        padding: 0px; /* Smaller padding */
      }
    
      .sea_taxi_crd_pic img {
        width: 100%; /* Increase image width slightly */
        top: -50px; /* Adjust top positioning */
        left: 0%; /* Adjust for better centering */
      }
    
      .sea_taxi_details_view_div_1 h2 {
        font-size: 16px; /* Smaller heading size */
      }
    
      .sea_taxi_details_view_div_1 p {
        font-size: 12px; /* Smaller font for paragraphs */
      }
    
      .flex-container {
        gap: 6px; /* Narrower gap for small screens */
        font-size: 12px; /* Smaller font size */
      }

      .top-bar-contact{
        display: none;
      }
    }


    