.settings {
  margin-top: 70px;
  padding: 0px 10px;
  padding-bottom: 10px;
}

.settings__wrapper {
  min-height: calc(100vh - 80px);
  padding: 50px;
  border-radius: 5px;
  background: var(--primary-color);
}

.settings__title {
  color: var(--heading-color2);
  font-weight: 500;
  margin-bottom: 2rem;
  font-size: 20px;
}

.settings__top {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  margin-bottom: 1.5rem;
}

.setting__btn {
  border: none;
  border: none;
  padding: 7px 25px;
  background: transparent;
  border-radius: 5px;
  color: var(--heading-color2);
  font-size: 15px;
  cursor: pointer;
}

.active__btn {
  background: #b7ffe94f;
}

.profile__title {
  color: var(--heading-color2);
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 17px;
}

.profile__desc {
  color: var(--small-text-color2);
  margin-bottom: 2rem;
  font-size: 13px;
}

label {
  color: var(--heading-color2);
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.form__group div {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.form__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  margin-bottom: 2rem;
}

.form__group div input {
  width: 100%;
  padding: 12px 20px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #b7ffe913;
  color: var(--heading-color2);
  font-size: 13px;
}

.form__group div input::placeholder {
  color: var(--small-text-color2);
  font-size: 13px;
}

.form__group div input:focus {
  outline: none;
}

.profile-img__desc {
  color: var(--small-text-color2);
  margin-bottom: 10px;
  font-size: 13px;
}

.profile__img-btns {
  display: flex;
  flex-direction: row !important;
  column-gap: 2.7rem;
}

.dlt__btn,
.update__btn {
  border: none;
  outline: none;
  background: transparent;
  color: #ddd;
  font-size: 0.9rem;
  cursor: pointer;
}

.update__btn {
  color: var(--secondary-color);
}

.Admin__image{
  height: 130px;
  width: 130px;
  margin-bottom: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.contactus {
  font-size: 14px;
  background-color: #ddd;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  color: var(--heading-color2); /* Corrected */
  max-width: 100%; /* Allow flexibility */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--primary-color);
}

.contactus-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
  column-gap: 2rem;
  row-gap: 2rem;
}

.form__group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.form__group div {
  flex: 1;
  margin-right: 1rem;
}

.add__contact-btn {
  padding: 10px 20px;
  background-color:#a55001;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add__contact-btn:hover {
  background-color: #bb8900;
}

.contactus i {
  float: right;
  cursor: pointer;
  color: red;
}

.error-message{
  color: red;
  font-size: 12px;
}