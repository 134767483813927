.bookings {
  margin-top: 70px;
  padding: 0px 10px;
  padding-bottom: 10px;
}
.booking__wrapper{
  min-height: calc(100vh - 80px);
  padding: 20px;
  background-color: white;
}

.booking__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.booking__title {
  font-size: 20px;
  color: #000000;
  margin-bottom: 2rem;
}

.filter__widget-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.filter__widget-01 select,
.filter__widget-02 select {
  border: none;
  padding: 7px 20px;
  border-radius: 50px;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  margin-bottom: 2rem;
}

.car__item {
  padding: 20px;
  border-radius: 5px;
  background: var(--primary-color);
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.car__item:hover{
  transform: scale(1.05);
}

.car__item-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__item-tile h3 {
  font-size: 1.3rem;
  color: white;
  font-weight: 400;
}

.car__item-tile span i {
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.63);
  cursor: pointer;
}

.car__item-top p {
  color: rgba(255, 255, 255, 0.63);
  font-size: 0.9rem;
  font-weight: 400;
}

.car__item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__bottom-left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.car__bottom-left p {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: rgba(255, 255, 255, 0.829);
  font-weight: 300 !important;
}

.car__bottom-left p i {
  color: white;
}

.car__rent {
  color: rgba(255, 255, 255, 0.829);
  font-size: 0.8rem;
}

.booking__car-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /* grid-template-columns: repeat(4, 1fr); */
  column-gap: 2rem;
  row-gap: 2rem;
}

.car__item-tile h3 {
  font-size: 1rem;
  font-weight: 400;
}

.car__item-top {
  margin-bottom: 15px;
}

.car__img {
  margin-bottom: 20px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.car__item:nth-child(2) .car__img img,
.car__item:nth-child(6) .car__img img,
.car__item:nth-child(8) .car__img img {
  width: 75%;
}

.car__item:nth-child(4) .car__img img {
  width: 85%;
}
