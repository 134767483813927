/* Footer Container */
.footer {
    background-color: #01559b;
    color: #ffffffb4;
    width: 100%;
  }
.footer_hed_main_div{
  display: flex;
  gap: 50px;
  padding: 40px 40px 20px 40px; 
}  

.footer_hed_main_div h2{
  font-size: 18px;
 
}
.footer_main_div_1,.footer_app_div,.footer_address_div{
  width: 100%;
}
.line_div{
  content: ""; 
  border-bottom: 2px solid #FDC100;
  margin: 10px 0px;
}
.footer_main_div_1 p,.footer_app_div_1 p, .footer_address_div_1 p{
  text-align: justify;
  line-height: 1.9;
  font-size: 14px;
  color: rgb(221, 221, 221);
  padding-bottom: 10px;
}


.footer_social_icons {
  margin-top: 20px;
}

.footer_social_icons a {
  color: #fff; /* Icon color */
  font-size: 24px;
  margin: 0 15px;
  transition: color 0.3s ease;
}

.footer_social_icons a:hover {
  color: #ffdd00; /* Yellow on hover */
}

.footlogo{
  width: 200px;
}

.footerlisttile {
  display: flex;
  width: 100%;
  border-radius: 8px;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
}
.footerlisttile i{
 font-size: 15px;
 margin-right: 10px;
 color: black;
 padding: 10px;
 background-color: #ffc400;
 border-radius: 10px;
}

.footerlisttile h4{
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.footerlisttile p{
  font-size: 14px;
  color: white;
}

.privacyDiv{
   width: 100%;
   text-align: center;
   font-size: 12px;
   color: black;
   padding: 20px 0px;
   background-color: rgba(126, 126, 126, 0.137);
}

.privacyDiv span{
  color: rgb(255, 187, 0);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}


.footerlinks p:hover{
  cursor: pointer;
  text-decoration: underline;
  color: #FDC100;
}

@media (max-width: 768px) {
  .footerlisttile-container {
    flex-direction: column;
    align-items: center;
  }

  .footerlisttile {
    width: 80%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .footer_hed_main_div {
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center the content */
    gap: 15px; /* Adjust gap for mobile */
    padding: 15px; /* Reduce padding for mobile */
  }

  .footer_main_div_1, .footer_app_div, .footer_address_div {
    text-align: center; /* Center text for mobile */
    width: 90%; /* Adjust width */
    margin: 10px 0; /* Add spacing between items */
  }

  .footer_main_div_1 p {
    font-size: 14px; /* Reduce font size */
    line-height: 1.7; /* Adjust line height */
  }

  .footer_social_icons {
    margin-top: 15px; /* Reduce top margin */
  }

  .footer_social_icons a {
    margin: 0 10px; /* Reduce icon spacing */
    font-size: 22px; /* Slightly reduce icon size */
  }
  .footer_app_div_1 h2,.footer_main_div_1 h2,.footer_address_div_1 h2{
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .footer_hed_main_div {
    padding: 10px; /* Further reduce padding for smaller screens */
  }

  .footer_main_div_1 p {
    font-size: 13px; /* Reduce font size for smaller screens */
    line-height: 1.5; /* Adjust line height */
  }

  .footer_social_icons a {
    margin: 0 8px; 
    font-size: 20px; /* Reduce icon size */
  }
}