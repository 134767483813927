.sea_taxi_contact_page{
    background-color: #F5F7FB;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
}
.sea_taxi_page{
    padding: 8%;
}
.sea_taxi_contact_page_1{
    width: 100%;
    height: auto;
    display: flex;  
}
.sea_taxi_contact_pic_add{

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px 0px 0px 10px;
    background-color: transparent;
}
.sea_taxi_conts_input{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #fff;
    border-radius: 0px 10px 10px 0px;
}
.sea_taxi_contact_pic_add,.sea_taxi_conts_input{
    width: 100%;
}
.sea_taxi_contact_pic_add img{
   object-fit: cover;
   width: 100%;
   height: 100%;
    border-radius: 10px 0px 0px 10px;
}
.sea_taxi_conts_input{
    padding: 40px;
}
.contct_bts{
  text-align: center;
  margin-top: 20px;
}
.contct_bts button{
    padding: 10px 35px;
    background-color: #0174D3;
    color: #fff;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.sea_taxi_conts_input h1{
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.712);
}
.sea_taxi_conts_input h2{
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0px;
}
.sea_taxi_conts_input input{
    width: 100%;
    padding: 10px;
    border-radius: 9px;
    border: 1px solid #333;
}
.sea_taxi_conts_input textarea{
    padding: 10px;
    width: 100%;
    height: 100px;
    border-radius: 9px;
    border: 1px solid #333;
}
.erroralert{
    color: red;
    font-size: 12px;
}


@media screen and (max-width: 768px) {
    .sea_taxi_contact_page{
        margin-top: 50px;
    }

    .sea_taxi_page {
        padding: 5%;
        margin-left: 0; 
    }

    .sea_taxi_contact_page_1 {
        flex-direction: column; /* Stack items vertically */
        align-items: center;    /* Center items */
        margin-top: 70px;
    }

    .sea_taxi_contact_pic_add {
      display: none;
    }

    .sea_taxi_conts_input {
        border-radius: 0px 0px 10px 10px; /* Change the border radius for the bottom */
        width: 78%; /* Adjust width for mobile */
    }

    .sea_taxi_contact_pic_add img {
        margin-top: 10%;
        width: 100%; /* Make image responsive */
        height: auto; /* Preserve aspect ratio */
    }

    .contct_bts button {
        padding: 8px 20px; /* Adjust button padding */
        font-size: 14px; /* Adjust font size */
    }

    .sea_taxi_conts_input h1 {
        font-size: 22px; /* Adjust heading size */
    }

    .sea_taxi_conts_input input, .sea_taxi_conts_input textarea {
        width: 95%; /* Make input fields fully responsive */
        font-size: 14px; /* Adjust font size */
    }

    .sea_taxi_conts_input textarea {
        width: 100%; /* Adjust textarea width */
        height: 80px; /* Adjust height for mobile */
    }
}

@media screen and (max-width: 480px) {
    .sea_taxi_conts_input {
        padding: 20px; /* Reduce padding for smaller screens */
        margin-top: -10px;
    }

    .contct_bts button {
        padding: 8px 15px; /* Adjust button size for smaller screens */
    }
}