.sea_taxi_about_main_div{
  background-color: #F5F7FB;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
    
}
.about_taxi_pic_add,.about_taxi_about_conts{
    width: 100%;
}
.about_taxi_pic_add img{
    width: 100%;
    padding: 30px;
}
.sea_taxi_about_main_div_1{
    display: flex;
    gap: 20px;
}
.about_taxi_about_conts{
    margin: auto;
    padding: 10px;
    padding-right: 30px;
}
.about_taxi_about_conts h4{
    text-align: justify;
    font-size: 15px;
    color: black;
    line-height: 1.9;
    margin-top: 10px;
    font-weight: bold;
}
.about_taxi_about_conts p{
    text-align: justify;
    font-size: 14px;
    color: #777;
    line-height: 1.9;
    margin-top: 10px;
}
.about_line_div{
    content: "";
    width: 100%;
    border-bottom: 1px solid #2A7AC7;
}
.about_taxi_about_conts h2{
  margin:10px 0px;
  color: rgba(0, 0, 0, 0.788);
  font-size: 30px;
  font-weight: bolder;
}
body{
    overflow-x: hidden;
}


@media screen and (max-width: 768px) {
    .sea_taxi_about_main_div_1 {
      flex-direction: column; /* Stack image and content vertically */
      gap: 15px; /* Reduce gap for mobile */
    }
  
    .about_taxi_about_conts {
      padding: 5px; /* Adjust padding for mobile */
      width: 95%; /* Reduce width */
    }
  
    .about_taxi_about_conts h2 {
      font-size: 22px; /* Adjust heading size */
      text-align: center; /* Center heading text */
    }
  
    .about_taxi_about_conts p {
      font-size: 18px; /* Adjust font size for mobile */
      line-height: 1.7; /* Reduce line height */
    }
  
    .about_line_div {
      margin: 10px auto; /* Add top and bottom margin */
      width: 80%; /* Adjust width of line divider */
    }
    .about_taxi_pic_add img {
      width: 100%;
      margin-top: 50px;
  }
  }
  
  @media screen and (max-width: 480px) {
    .sea_taxi_about_main_div_1 {
      gap: 10px; /* Reduce gap for smaller screens */
    }
  
    .about_taxi_about_conts h2 {
      font-size: 20px; /* Further reduce heading size */
    }
  
    .about_taxi_about_conts p {
      font-size: 16px; /* Smaller font size */
    }
  
    .about_line_div {
      width: 70%; /* Narrower line for small screens */
    }
  }