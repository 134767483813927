/* Container */
.vehicle-update {
  min-height: calc(100vh - 80px);
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .vehicle-update__heading {
    text-align: start;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  /* Table Styling */
  .vehicle-update__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    /* table-layout: fixed; */
  }
  
  .vehicle-update__thead-row {
    background-color: var(--primary-color);
    color: white;
  }
  
  .vehicle-update__thead-cell {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;

    word-wrap: break-word;
    white-space: normal; 
  }
  
  .vehicle-update__tbody-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .vehicle-update__tbody-row:hover {
    background-color: #dbdbdb;
  }

  .vehicle-update__tbody-cell {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #495057;

    max-width: 200px; 
    overflow-wrap: break-word; 
  }
  
  /* Profile Image Styling */
  .vehicle-update__image {
    width: 80px;
    height: 60px;
    border-radius: 1%;
    object-fit: cover;
  }
  .vehicle-updateGallery__image {
    width: 100px;
    height: 70px;
    border-radius: 1%;
    object-fit: cover;
    padding: 10px;
  }
  
  .vehicle-update__proof-image {
    width: 80px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  /* Action Buttons */
  .vehicle-update__action-btn {
    padding: 6px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .updateview-btn {
    background-color: #28a745;
    color: white;
  }

  .updateaccept-btn {
    background-color: #b6a400;
    color: white;
  }
  
  .updatereject-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .updatedelete-btn {
    background-color: #dc3545;
    color: white;
  }
  
  /* Hover Effects */
  .vehicle-update__action-btn:hover {
    opacity: 0.8;
  }
  

  .updatevehno--data{
    margin-top: 200px;
    color: red;
    align-items: center;
    font-size: 0.9rem;
  }

  .vehicle-update__update-details {
    background-color: #ffebeb; 
    padding: 10px;
    border-top: 2px solid #ddd; 
  }
  
  .vehicle-update__update-details td {
    padding: 15px;
  }
  
  .vehicle-update__update-details p {
    margin: 5px 0; /* Reduce margin between paragraphs */
    font-size: 14px; /* Adjust font size */
    color: #333; /* Text color */
  }
  
  .vehicle-update__update-details strong {
    color: #007bff; /* Highlighted text */
    font-weight: 600; /* Make it bold */
  }
  
  .vehicle-update__update-details div {
    display: flex;
    flex-direction: column; /* Align text vertically */
    gap: 10px; /* Spacing between items */
  }
  
  .vehicle-update__update-details td[colspan="8"] {
    border-left: 4px solid #007bff; /* Add a vertical line for emphasis */
    padding-left: 20px; /* Offset text from the line */
  }
  

  .updateimgflex{
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
  }

  .updateGlry__title{
    color: #dc3545;
  }

/* /////////////////////////////////////////////////////////////////// */

.update-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.update-popup-body{
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.update-popup h3{
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: center;
}
.update-popup h4{
  margin-top: 10px;
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.update-popup pre {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}

.update-popup .gallery {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.update-popup .gallery img {
  max-width: 80px;
  height: auto;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.close-popup-btn {
margin-left: 5px;
  padding: 6px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-popup-btn:hover {
  background-color: #0056b3;
}

.updatepopfoot{
  float: right;
  margin-top: 20px;
  justify-content: space-between;
}



.popup-content p{
  color: red;
  font-size: 0.8rem;
  text-align: center;
  margin: 10px;
}


.upgallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.upgallery-item {
  position: relative;
  width: 100px; /* Adjust based on your image size */
}

.upgaldelete-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  color: red;
  font-size: 17px; /* Adjust size if needed */
  background-color: rgba(255, 255, 255, 0.7); /* Optional: to make the icon stand out */
  border-radius: 50%; /* Optional: for circular icon */
}

.upgallery-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
