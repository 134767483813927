/* Table Container */
.viewbook-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .viewbook_headmargin {
    margin-top: 10px;
    margin-bottom: 10px;
  } 
 
.viewbook_activetitleTop{
  display: flex;
  justify-content: space-between;
}


/* //////////////////////////////// */

.bookView__car {
  margin-top: 100px;
  padding: 0px 30px;
  padding-bottom: 50px;
}

.bookView__car-img {
  padding: 10px;
  background: white;
  border-radius: 5px;
  height: 370px;
  text-align: center;
  cursor: pointer;
}

.bookView__car-img img {
  object-fit: cover;
  width: 90%;
  height: 90%;
}

.bookView__car-img h2 {
  color: var(--heading-color2);
  text-align-last: left;
}

.bookView__car-top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* column-gap: 2rem; */
}

.bookvehicle__history {
  align-items: center;
  padding: 10px ;
  padding-right: 50px;
  border-radius: 5px;
  background: white;
  height: auto; 
  padding-bottom: 20px;
  cursor: pointer;

}

.bookvehicle__history h2 {
  margin-bottom: 10px;
  color: var(--primary-color);
  font-size: 1rem;
  margin-top: 10px;
}

.bookvehicle-details {
  align-items: flex-start;
  gap: 2rem;
  margin-top: 20px;
}

.bookvehicle-info, .bookdriver-info {
  background-color: var(--body-bg);
  flex: 2;
  color: var(--primary-color);
min-width: 50%;
}

.vehicle-info p, .driver-details p {
  margin-bottom: 10px;
  font-size: 0.8rem;

}

.bookdriver-info {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.bookdriver-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.bookdriver-details {
  display: flex;
  flex-direction: column;
}

.book-info p , .bookdriver-details p, .book-info2 p{
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 10px;
}


.View__book-title {
  margin-bottom: 10px;
  color: var(--primary-color);
  font-size: 1.5rem;
}

.bookvehicle-details{
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}
.subdiv{
  display: flex;
}
.subdiv2{
  display: flex;
}

.book-info , .subdiv, .book-info2{
  margin-right: 30PX;
}



  
 