.Loginpage {
  display: flex;
    background-color: #f4f4f4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    height: 100vh;
    font-family: none;
}

.Loginpage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05); 
  z-index: 1;
  /* backdrop-filter: blur(8px); */
}

.logdiv{
  flex:1.5;
  display: flex;
  align-items: center;
  justify-content: center;

   background-color: rgba(255, 255, 255, 0.7); /* Transparent white for the blur effect */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  

}

.logincontainer {
  position: relative;
  z-index: 2; 
  width: 100%;
  max-width: 400px;
  padding:40px 60px;
  border: 1px solid #81818142;
  padding-bottom: 60px;
  border-radius: 10px;
  /* box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.5); */
  justify-content: center;
  align-items: center;
  float: left;
}


.imgseataxi {
  height: 100px;
  width: 150px;
  background-image: url('../../assets/images/logo.png'); 
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%) brightness(0) sepia(100%) hue-rotate(190deg) saturate(300%);
  /* You can adjust the filter properties to get the desired color effect */
}

  .title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .loglabel {
    font-weight: bold;
    font-size: 13px;
    display: block;
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: left;
    color: var(--primary-color);
  }
  
  .loginput {
    /* width: 100%;
    height: 40px;
    padding: 15px ;
    border-radius: 15px;
    border: 1px solid #F4F7FE;
    font-size: 13px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
    
    height: 40px;
    width: 100%;
    padding: 13px 0px 13px 45px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}
  .lableimg {
    position: absolute;
    left: 10px;
    bottom: 15px;
    font-size: 16px;
    color: #aaa;
    max-height: 25px;
    max-width: 25px;
    object-fit: cover;
    overflow: hidden;
}

  
  .loginput:focus { 
    border: 2px solid transparent; outline: none; 
    background-color: white;
  }

  .otp-inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  padding: 10px;
}

.otp-digit {

    text-align: center;
    border: 1px solid #F4F7FE;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

  .logbutton {
    padding: 13px;
    border-radius: 4px;
    width: 100%;
    border: none;
    background-color: #181b3a;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    
    background-image: linear-gradient(to right, #F29100, #F29100);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    transition: background-size 0.8s ease; /* Smooth transition */
  }
  
  .logbutton:hover {
    background-size: 100% 100%;
    color: black; 
  }
  
  
  
.resendbtntimr{
  font-size: 12px;
  color: red;
  text-align: center;
  margin-bottom: 15px;
 } 

.resendbtn{
  text-align: center;
  margin-bottom: 15px;
  color: #035faa;
  cursor: pointer;
}


  .custom-popup {
    background-color: #f0f9ff; /* Light background color */
    border-radius: 10px;       /* Rounded corners for a softer look */
    padding: 20px;             /* Adjust padding inside the popup */
  }
  
  .custom-title {
    color: var(--primary-color);            /* Custom title color */
    font-size: 1.5rem;         /* Larger font size */
    margin-bottom: 1rem;       /* Space below the title */
  }
  
  .custom-content {
    color: #333;               /* Darker text color */
    font-size: 1rem;           /* Standard font size */
    margin-top: 1rem;          /* Space above the content */
    text-align: center;        /* Center the content text */
  }
  
  .logspinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top: 2px solid white;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
  }
  

  .login-image-container {
    display: flex;
    flex-direction: column;
    flex: 1.5;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to top right, #ffffff 50%, #ff9900 50%);
    overflow: hidden;
    padding-top:50px
  }
  
  .login-content {
    flex: 1;
    max-width: 400px;
    color: black;
    z-index: 2;
    text-align: center;
   
  }
  
  .login-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .login-content p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* .signin-button {
    background-color: #fff;
    color: #ff9900;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
  } */
  
  .signin-button:hover {
    background-color: #F29100;
  }
  
  .image-section {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .login-image {
    min-width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
  }


/* .login-image {
  width: 100%; 
  height: 100%;
  object-fit: cover;
} */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 768px) {


    .logincontainer {
      max-width: 300px;
      padding: 40px 30px;
    }

.login-image-container{
  display: none;
}

  }
