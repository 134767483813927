@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Carlito:ital,wght@0,400;0,700;1,400;1,700&display=swap"); */

:root {
  --primary-color: #181b3a;
  /* --primary-color: #0174D3; */
  --secondary-color: #181b3a;
  /* --body-bg: #F4F7FE; */
  --body-bg: #eeeeee;
  --card-01-bg: linear-gradient(#ef621c, #e1424e);
  --card-02-bg: linear-gradient(#01d293, #56c57a);
  --card-03-bg: #725cff;
  --card-04-bg: #2884ff;
  --card-05-bg: linear-gradient(#ef941c, #f16929);
  --heading-color: #181b3a;
  --small-text-color: #0a0b1a;
  --heading-color2: #ffffff;
  --small-text-color2: rgba(255, 255, 255, 0.63);
  --recommend-car01-bg: #e1dfa4;
  --recommend-car02-bg: #e3ecf1;
  --recommend-car03-bg: #f4e3e5;
}

/* For Chrome, Safari, and Edge */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 10px; 
} */

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #ff6f61, #181b3a);
  border-radius: 5px;
}

body, html {
  margin: 0;
  padding: 0;
}
/* For Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
} */


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
  /* font-family: 'Verdana', sans-serif; */
  /* font-family: "Carlito", sans-serif; */
  background: var(--body-bg);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

.main__layout {
  margin-left: 260px;
}

img {
  width: 100%;
}


