/* Table Container */
.viewvehicle-container {
    width: 100%;
    min-height: calc(100vh - 80px);
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .viewvehicle_headmargin {
    /* font-size: 20px; */
    margin-top: 10px;
    margin-bottom: 10px;
  } 
 
.viewvehicle_activetitleTop{
  display: flex;
  justify-content: space-between;
}


/* //////////////////////////////// */

.View__car {
  margin-top: 100px;
  padding: 0px 30px;
  padding-bottom: 50px;
}

.View__car-img {
  padding: 10px;
  background: white;
  border-radius: 5px;
  height: 370px;
  width: 90%;
  text-align: center;
  cursor: pointer;
}

.View__car-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.View__car-img h2 {
  color: var(--heading-color2);
  text-align-last: left;
}

.View__car-top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* column-gap: 2rem; */
}

.vehicle__history {
  padding: 10px ;
  padding-right: 50px;
  border-radius: 5px;
  background: white;
  height: auto; 
  cursor: pointer;
}

.vehicle__history h4 {
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--primary-color);
  font-size: 1rem;
}

.vehicle-details {
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
 
}

.vehicle-info, .driver-info {
  background-color: var(--body-bg);
  padding: 10px 10px;
  flex: 1;
  color: var(--primary-color);
}

.vehicle-info p, .driver-details p {
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.driver-info {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.driver-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.driver-details {
  display: flex;
  flex-direction: column;
}

.vehicle-info p strong, .driver-details p strong {
  font-weight: bold;
}


.View__car-title {
  margin-bottom: 10px;
  color: var(--primary-color);
  font-size: 20px;
}



.viewvehicle_activeDeactive_btn{
  padding: 10px 30px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;

}


.viewvehicle_Gal-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 0px;
  }
  
  .galvehimgdiv {
    text-align: center;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
.viewvehicle_Gal-list div:hover {
    transform: scale(1.05);
  }
  
  .gallery-photo {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;

  }
  
  .no--gallry{
    color: red;
    font-size: 0.8rem;

  }

/* ////////////////////////////////////////////// */

/* Modal overlay style */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Dark background with transparency */
  z-index: 999; /* Ensures the modal is on top */
}

/* Modal content style */
.modal {
  position: relative;
  margin: auto;
  background:  rgba(0, 0, 0, 0.034);
  border-radius: 10px;
  width: 80%; /* Make modal responsive */
  max-width: 800px; /* Maximum width for larger screens */
  padding: 20px;
  z-index: 1000;
  animation: slide-down 0.5s ease-out;
}

/* Animation for modal appearance */
@keyframes slide-down {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Close button style */
.sliderclose-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  font-size: 30px;
  background-color: white;
  padding: 5px 10px;
  color: #ff3e3e; /* Red close button */
  cursor: pointer;
  z-index: 1001;
  transition: color 0.3s ease;
}

.sliderclose-btn:hover {
  color: #ff1a1a; /* Darker red on hover */
}

/* Slider style */
.slick-prev, .slick-next {
  z-index: 1;
  font-size: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black for arrows */
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.slick-prev:before, .slick-next:before {
  font-size: 24px;
  color: white; /* White arrow icons */
}

.slick-prev:hover:before, .slick-next:hover:before {
  color: #ff3e3e; /* Red arrows on hover */
}

.slider-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Set a fixed height for the slider */
  background-color: #f0f0f0; /* Light gray background for images */
  padding: 10px;
}

.slider-photo {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px; /* Rounded corners for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for images */
  object-fit: cover; /* Ensures the image maintains aspect ratio */
}

/* Slick dots style */
.slick-dots li button:before {
  font-size: 12px;
  color: #ccc; /* Light gray for dots */
}

.slick-dots li.slick-active button:before {
  color: #ff3e3e; /* Red dot for active slide */
}


 