/* General Styling */
.privacy-policy-container {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  /* background-color: #f9f9f9; */
}

.privacy-policy-title {
  text-align: center;
  font-size: 1.8em;
  color: #003366;
  padding: 20px;
}

.privacy-policy-updated {
  text-align: center;
  font-size: 1em;
  color: #666;
  margin-bottom: 15px;
}

.privacy-policy-section {
  margin-bottom: 30px;
}

.privacy-policy-subtitle {
  font-size: 1.5em;
  color: #003366;
  margin-bottom: 15px;
}

.privacy-policy-text {
  font-size: 1em;
  line-height: 1.8;
  color: #444;
  margin-bottom: 20px;
}

.privacy-policy-list {
  list-style-type: disc;
  padding-left: 20px;
}

.privacy-policy-list-item {
  font-size: 1em;
  margin-bottom: 10px;
}

.privacy-policy-link {
  color: #0055cc;
  text-decoration: none;
  margin-bottom: 10px;
}

.privacy-policy-link:hover {
  text-decoration: underline;
}

.privacy-policy-phone {
  color: #0055cc;
}
.marginbotm{
  margin-bottom: 10px;
}

.policyrow {
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.policycol1 {
  width: 60%;  /* Takes up 60% of the row */
}

.policyrow img {
  width: 40%; 
  height: auto; 
  overflow: none;
  object-fit: cover;   
  flex-shrink: 0;

}
 


/* Mobile Responsive Design */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 15px;
  }

  .privacy-policy-title {
    font-size: 2em;
  }

  .privacy-policy-subtitle {
    font-size: 1.5em;
  }

  .privacy-policy-text, .privacy-policy-list-item {
    font-size: 1em;
  }

  .policycol1 {
    width: 100%; 
  }

  .policyrow {
   flex-direction: column;
   align-items: start; 
   justify-content: center;
  }
}
