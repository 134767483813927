.typesettings {
    margin-top: 70px;
    padding: 0px 10px;
    padding-bottom: 10px;
  }
  
  .typesettings__wrapper {
    min-height: calc(100vh - 80px);
    padding: 50px;
    border-radius: 5px;
    background: var(--primary-color);
  }
  
  .typesettings__title {
    color: var(--heading-color2);
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  .typesettings__top {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    margin-bottom: 1.5rem;
  }
  
  .typesetting__btn {
    border: none;
    border: none;
    padding: 7px 25px;
    background: transparent;
    border-radius: 5px;
    color: var(--heading-color2);
    font-size: 15px;
    cursor: pointer;
  }
  
  .typesetting__btn.active__btn {
    background: #b7ffe94f;
  }
  
  .typesetting__btn:hover {
    /* background-color: #929292; */
    color: #fff;
  }
  
.typevehType__form h3{
  text-decoration-line: underline;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
}

  
  .typeform__group {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .typeform__group input, .form__group select {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .typeadd__btn {
    background-color: #087c0c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .typeadd__btn:hover {
    background-color: #45a049;
  }
  
  .vehType-list {
    margin-top: 30px;
  }
  .vehType-list h4{
    margin-top: 30px;
    color: white;
    font-weight: 500;
  }
 
  /* Flex container for the cards */
 /* Container for vehicle type cards */
.vehType-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0px;
  }
  
  /* Individual card for each vehicle type */
  .vehType-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center; /* Align items centrally */
    flex-direction: column; /* Stack delete icon and text vertically */
    width: calc(15% - 10px); /* 15% width with gap adjustment */
    min-height: 50px;
    position: relative; /* Relative positioning for floating icon */
    text-align: center; /* Center align the text */
  }
  
  /* Adjusting the card text */
  .vehType-card p {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-top: 10px; /* Add some space between text and icon */
  }
  
  /* Styling the delete icon */
  .typedelete-icon {
    position: absolute; /* Floating delete icon in the top right corner */
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: red;
    cursor: pointer;
  }
  
  /* Responsive behavior */
  @media (max-width: 768px) {
    .vehType-card {
      width: calc(50% - 15px); /* 2 cards per row on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .vehType-card {
      width: calc(100% - 15px); /* 1 card per row on very small screens */
    }
  }
  
  
  
  /* Main container for vehicle category form */
  .vehCategory__form {
    display: contents;
    float: left;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
}

/* Form heading */
.vehCategory__form h3 {
  /* text-decoration: underline; */
  text-decoration-line: underline;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    text-align: left; /* Align heading to the left */
}

/* Form group styling */
.cateform__group, .typeform__group {
    margin-bottom: 20px;
}

/* Label styling */
.cateform__group p {
    display: block; /* Ensures label is on a new line */
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    color: white;
    text-align: left; /* Align label to the left */
}

/* Select input styling */
.vehCategory__form select {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    margin-top: 5px; /* Optional: adds a little space between label and select */
}

/* Input field styling */
.vehCategory__form input[type="text"] {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
}

/* Add category button styling */
.add__category-btn {
    display: block;
    max-width: 20%;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add__category-btn:hover {
    background-color: #218838;
}

/* Category list container */
.category-list {
    margin-top: 30px;
}

/* Category list heading */
.category-list h4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(238, 236, 236);
    text-align: left; /* Align category list heading to the left */
}

/* Styling the category list */
.category-list ul {
    list-style-type: none;
    padding: 0;
}

.category-list ul li {
    padding: 10px;
    background-color: #ffffff1c;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 16px;
    color: white;
}

/* Empty category message */
.category-list p {
    font-size: 16px;
    color: white;
    text-align: center; /* Center the empty message */
}

.subcate-li{
    background-color: white !important;
    margin-right: '10px';
     display: 'flex';
    align-items: 'center' ;
    color: var(--primary-color) !important;
}
.subcate-li i{
    color: red; 
}

  