.staff-form-container {
  max-width: 900px;
  max-height: 90vh;
  margin: 0 auto;
  padding: 20px 50px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Add vertical scrolling */
}

  
  .staff-form-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .add-staff-form {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
  }
  
  .add-staff-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .add-staff-label {
    flex: 1;
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
    color: #333;
  }
  
  .add-staff-input {
    flex: 2;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .add-staff-input.orange-border {
    border-color:  #0056b3;
  }
  
  .add-staff-actions {
    text-align: center;
  }
  
  .add-staff-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-staff-btn-submit {
    background-color:  #0056b3;
    color: #fff;
  }
  
  .add-staff-btn-cancel {
    background-color: #f5f5f5;
    color: #333;
    margin-left: 10px;
  }
  
  .staffdelete{
    padding: 5px 10px;
    background-color: rgba(133, 0, 0, 0.849);
    border-radius: 5px;
    color: #ddd;
    border: none;
  }

  .staffdelete:hover{
    background-color: red;
  }



  .add-staff-row-access {
    padding: 16px;
  }
  
  .access-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust as needed */
    gap: 16px;
  }
  
  .access-category {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
  }
  
  .access-category h4 {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .access-actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust based on actions */
    gap: 5px;
  }
  
  .access-action {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .access-grid label {
    color: black;
    font-size: 12px; /* Optional: Adjust font size if needed */
    margin-left: 4px; /* Optional: Add some space between the checkbox and the label */
  }
  
  .access-action input[type="checkbox"] {
    accent-color: black; /* Optional: Change checkbox accent color to black (supported in modern browsers) */
  }

  .view-access{
    padding: 5px 10px;
    background-color: green;
    border-radius: 5px;
    color: #ddd;
    border: none;
  }

  .view-access:hover{
    background-color: rgb(2, 199, 2);
  }

  /* //////////////////////////////////////// */

  /* Backdrop Overlay */
.access-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Modal Container */
.access-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px;
  max-width: 90%;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Modal Title */
.access-modal h3 {
  margin: 0 0 15px;
  font-size: 18px;
  text-align: center;
  color: #333;
}

/* Category Headers */
.access-modal h4 {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

/* Checkbox Labels */
.access-modal label {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.access-modal input[type="checkbox"] {
  margin-right: 10px;
}

/* Buttons */
.access-modal button {
  display: inline-block;
  margin: 10px 5px 0;
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.access-modal button:first-of-type {
  background-color: #007bff;
  color: #fff;
}

.access-modal button:first-of-type:hover {
  background-color: #0056b3;
}

.access-modal button:last-of-type {
  background-color: #ccc;
  color: #333;
}

.access-modal button:last-of-type:hover {
  background-color: #bbb;
}

/* Scrollable Content */
.access-modal {
  max-height: 90%;
  overflow-y: auto;
}

.mandatory{
  color: red;
}
.staff-form-container::-webkit-scrollbar {
  width: 8px;
}

.staff-form-container::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}

.staff-form-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}