/* Container */
.dealer-requests {
  min-height: calc(100vh - 80px);
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .dealer-requests__heading {
    text-align: start;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  /* Table Styling */
  .dealer-requests__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dealer-requests__thead-row {
    background-color: var(--primary-color);
    color: white;
  }
  
  .dealer-requests__thead-cell {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
  }
  
  .dealer-requests__tbody-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  .dealer-requests__tbody-row:hover {
    background-color: #dbdbdb;
  }
  
  .dealer-requests__tbody-cell {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #495057;
  }
  
  /* Profile Image Styling */
  .dealer-requests__profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .dealer-requests__proof-image {
    width: 50px;
    height: 40px;
    object-fit: cover;
  }
  
  /* Action Buttons */
  .dealer-requests__action-btn {
    padding: 6px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .accept-btn {
    background-color: #28a745;
    color: white;
  }
  
  .reject-btn {
    background-color: #a55001;
    color: white;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }
  
  /* Hover Effects */
  .dealer-requests__action-btn:hover {
    opacity: 0.8;
  }
  

  .dlrno--data{
    margin-top: 200px;
    color: red;
    align-items: center;
    font-size: 0.9rem;
  }

  .dlrbinicon{
    font-size: 1.3rem;
    color: red;
    margin-left: 10px !important;
    text-align: end;
  }