/* Base Styles */

.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px;
    background-color: #0062b3;
    color: #ffffff;
    font-size: 14px;
    transition: transform 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 50px; /* Set a fixed height */
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }
  
  .top-bar-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .top-bar-email,
  .top-bar-contact,
  .top-bar-time {
    margin: 0 10px;
    display: flex; /* Makes the <a> tag a flex container */
    align-items: center; /* Aligns items vertically in the center */
    text-decoration: none; /* Removes underline from links */
    color: inherit; 
    font-size: 13px;
  }

 
  .top-nav.hide {
    transform: translateY(-100%); /* Slide up to hide */
}


  .topNavIcon-Img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}




.web-taxi-header {
  position: fixed; 
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1000; 
  background-color: #0174D3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  padding: 15px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}


.web-taxi-header.top{
    top: 0; 
  }
.web-taxi-header.hide {
    transform: translateY(-100%);
}

.web-taxi-logo img {
    margin-left: 20%;
    width: 120px;
}

/* Menu Styles */
.web-taxi-menu {
    display: flex;
    list-style: none;
}

.web-taxi-menu ul {
    display: flex;
    gap: 50px;
    position: relative;
  
}

.web-taxi-menu ul li {
    list-style: none;
}

.web-taxi-menu ul li a {
    color: white;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 0;
    transition: border-bottom 0.3s ease;
}

/* Underline on hover */
.web-taxi-menu ul li a:hover {
    border-bottom: 2px solid #FDC100;
}

/* Active Link Styling */
.active-link {
    border-bottom: 2px solid #FDC100;
}

/* Hamburger menu icon styles */
.web-taxi-menu-icon {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    height: 25px;
    cursor: pointer;
}

.web-taxi-menu-icon .icon-bar {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
}



.nav-actions {
    display: flex;
    gap: 10px;
   right: 50px;
  }
  
  .nav-login-btn{
    background-color: #cc8b00; /* Red */
    color: #ffffff;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .nav-login-btn:hover{
    background-color: #c0392b; /* Darker red */
  }


/* Responsive Styles */
@media (max-width: 768px) {

    .web-taxi-header {
        position: none;
    }

    .web-taxi-logo img {
        margin-left: 0%;
        width: 100px;
    }

    .web-taxi-menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px;
        right: 0;
        background-color: #0174D3;
        width: 100%;
        text-align: center;
        transition: max-height 0.3s ease-in;
        z-index: 1000;
        padding: 20px;

    }

    .web-taxi-menu.open {
        display: flex;
    }

    .web-taxi-menu ul {
        flex-direction: column;
        gap: 10px;
    }

    .web-taxi-menu-icon {
        display: flex;
        position: relative;
        right: 40px;
    }
}





