.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Higher z-index than the Car Details Popup */
}
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 400px; /* Set a fixed width */
    text-align: center; /* Center text inside modal */
  }
  
  .modal-content i {
    font-size: 80px;
    font-weight: lighter;
    color: #FACEA8;
    animation: scaleUp 0.5s ease-in-out;
  }

  .modal-content h3 {
    color: rgba(0, 0, 0, 0.781);
    margin-bottom: 15px; /* Space below the title */
    font-size: 1.5em; /* Make title larger */
  }
  
  .modal-content p {
    margin-bottom: 20px; /* Space below the message */
    font-size: 1em; /* Set message font size */
  }
  
  .modal-content button {
    padding: 10px 15px; /* Button padding */
    margin: 5px; /* Space between buttons */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em; /* Button font size */
    transition: background-color 0.3s ease; /* Smooth background transition */
  }
  
  .modal-content button:hover {
    background-color: #f0f0f0; /* Change color on hover */
  }
  
  .modal-content button:first-of-type {
    background-color: #007bff; /* Primary button color */
    color: white; /* Text color for primary button */
  }
  
  .modal-content button:first-of-type:hover {
    background-color: #0056b3; /* Darken primary button on hover */
  }
  
  .modal-content button:last-of-type {
    background-color: #dc3545; /* Secondary button color */
    color: white; /* Text color for secondary button */
  }
  
  .modal-content button:last-of-type:hover {
    background-color: #c82333; /* Darken secondary button on hover */
  }

  /* Define keyframes for scaleUp animation */
@keyframes scaleUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Animate the icon on modal open */
.popscale-up {
  animation: scaleUp 0.5s ease-in-out forwards;
}
  