/* ImageSlider.css */
.slider-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .slider-content {
    position: relative;
  }
  
  .slider-image {
    max-width: 100%;
    max-height: 80vh;
    min-width: 50%;
    min-height: 60vh;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
  }
  
  .slider-nav {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1001; /* Ensures the buttons are above the image */
  }
  
  .slider-nav.clicked {
    color: gray; 
  }

  .slider-prev {
    left: 20px; /* Position the previous button on the left side */
  }
  
  .slider-next {
    right: 20px; /* Position the next button on the right side */
  }
  
  .slider-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1rem;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  .slider-close:hover{
    color: red;
  }
  