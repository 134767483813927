.popmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* Lower z-index than the Confirmation Modal */
  }
  
  .popmodal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: auto;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
    max-height: 90vh;
  }
  
  .popmodal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .imgDIVpop{
   max-width: 400px;
  }
  .popmodal-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .popmodal-content {
   display: flex;
  }
  
  .popmodal-history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }



  
  .popmodal-details,
  .popdriver-details {
    flex: 1;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    text-align: left;
  }
  .popdriver-details{
    display: flex;
  }
  .popmodal-details h4,
  .popdriver-details h4 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  
  .popmodal-details p,
  .popdriver-details p {
    margin: 5px 0;
    font-size: 13px;
  }
  
  .popdriver-photo {
    width: 100%;
    max-width: 100px;
    height: auto;
    border-radius: 8px;
    margin-left: 10px;
  }
  
  .popmodal-actions {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 10px;
  }
  
  .vehicle-requests__action-btn {
    max-width: 100px;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
    flex: 1;
    margin: 0 5px;
  }
  
  .accept-btn {
    background-color: #28a745;
  }
  
  .reject-btn {
    background-color: #cf8700;
  }
  
  .delete-btn {
    background-color: #dc3545;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  