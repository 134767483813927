/* Container */
.vehicle-requests {
  min-height: calc(100vh - 80px);
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .vehicle-requests__heading {
    text-align: start;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  /* Table Styling */
  .vehicle-requests__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .vehicle-requests__thead-row {
    background-color: var(--primary-color);
    color: white;
  }
  
  .vehicle-requests__thead-cell {
    padding: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
  }
  
  .vehicle-requests__tbody-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .vehicle-requests__tbody-row:hover {
    background-color: #dbdbdb;
  }
  .vehicle-requests__tbody-cell {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #495057;
  }
  
  /* Profile Image Styling */
  .vehicle-requests__image {
    width: 80px;
    height: 60px;
    border-radius: 1%;
    object-fit: cover;
  }
  
  .vehicle-requests__proof-image {
    width: 80px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  /* Action Buttons */
  .vehicle-requests__action-btn {
    padding: 6px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .accept-btn {
    background-color: #28a745;
    color: white;
  }
  
  .reject-btn {
    background-color:#a55001;
    color: white;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }
  
  /* Hover Effects */
  .vehicle-requests__action-btn:hover {
    opacity: 0.8;
  }
  

  .vehno--data{
    margin-top: 200px;
    color: red;
    align-items: center;
    font-size: 0.9rem;
  }