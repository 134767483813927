.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .image-popup-content {
    min-width: 50%;
    min-height: 50%;
    max-width: 60%;
    max-height: 80%;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
  }
  

  .imgclose-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 24px;
  }
  
  .imgclose-button:hover {
    color: red; /* Optional: Add hover effect */
  }



/* /////////////////////////////////////////////// */

  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination__button {
    background-color: #002e5f94; /* Bootstrap primary color */
    color: white;
    border: none;
    padding: 7px 10px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 0.7rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination__button:hover:not(:disabled) {
    background-color: var(--primary-color); /* Darker shade */
  }
  
  .pagination__button:disabled {
    background-color: #c0c0c0; /* Light gray */
    cursor: not-allowed;
  }
  
  .pagination__button.active {
    background-color: var(--primary-color); /* Highlight active page */
  }
  