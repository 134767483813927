/* Table Container */
.dealer-details-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .headmargin {
    margin-bottom: 20px;
    font-size: 20px;
  } 
 .emptymsgstyle{
  font-size: small;
  color: red;
 }


.activetitleTop{
  display: flex;
  justify-content: space-between;
}

.activeDeactive_btn{
  padding: 10px 30px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;

}

  
  /* Dealer Info Table */
  .dealer-info-table,
  .vehicle-details-table,
  .booking-details-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .dealer-info-table thead th,
  .vehicle-details-table thead th,
  .booking-details-table thead th {
    background-color: #343a40;
    color: #ffffff;
    text-align: left;
    padding: 10px;
    border: 1px solid #dee2e6;
    font-size: .9rem;
  }
  
  .dealer-info-table tbody td,
  .vehicle-details-table tbody td,
  .booking-details-table tbody td {
   padding-left: 10px;
    border: 1px solid #dee2e6;
    text-align: left;
    vertical-align: middle;
    font-size: .9rem;
  }
  
  .dealer-info-table tbody tr:nth-child(even),
  .vehicle-details-table tbody tr:nth-child(even),
  .booking-details-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .dealer-info-table tbody tr:hover,
  .vehicle-details-table tbody tr:hover,
  .booking-details-table tbody tr:hover {
    background-color: #dbdbdb;
  }
  
  /* Unique styles for images */
  .dlrprofile-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .dlrid-proof-photo {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  .vh-photo {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  .vh-proof-photo {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  
  /* Responsive design */
  @media screen and (max-width: 768px) {
    .dealer-info-table,
    .vehicle-details-table,
    .booking-details-table {
      font-size: 0.9rem;
    }
  
    .profile-photo {
      width: 60px;
      height: 60px;
    }
  
    .id-proof-photo {
      width: 80px;
      height: 80px;
    }
  }
  