/* General Wrapper */
.change-password-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f7fc;
    margin-top: 70px;
    
  }
  
  .change-password-container {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
    box-sizing: border-box;
  }
  
  .change-password-title {
    text-align: center;
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  /* Input Fields */
  .input-group {
    margin-bottom: 20px;
    padding: 0px 20px;

  }
  
  .input-label {
    display: block;
    color: #555;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #181b3a;
    background-color: #fff;
  }
  
  .input-field::placeholder {
    color: #aaa;
  }
  
  /* Error & Success Messages */
  .message {
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  
  .error {
    color: red;
  }
  
  .success {
    color: green;
  }
  
  /* Submit Button */
  .submit-button {
    width: 90%;
    padding: 14px;
    background-color: #181b3a;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    
  }
  
  .submit-button:hover {
    background-color: #2d3268;
  }
  
  .submit-button:focus {
    outline: none;
  }
  .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-field {
    width: 100%;
    padding-right: 40px; /* Leave space for the eye icon */
  }
  
  .toggle-password {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #999;
    font-size: 1.2rem;
  }
  .toggle-password:hover {
    color: #333;
  }
  