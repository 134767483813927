/* Container for filter inputs */
.history-filter-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: center;
  }
  
  /* Common style for input fields */
  .history-filter-controls input[type="date"],
  .history-filter-controls select,
  .history-filter-controls input[type="text"] {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    width: 200px; /* Adjust width as needed */
  }
  
  /* Input hover and focus effect */
  .history-filter-controls input[type="date"]:hover,
  .history-filter-controls select:hover,
  .history-filter-controls input[type="text"]:hover,
  .history-filter-controls input[type="date"]:focus,
  .history-filter-controls select:focus,
  .history-filter-controls input[type="text"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  }

 .Selected-delete-button{
    padding: 5px 10px;
    background-color: rgba(133, 0, 0, 0.849);
    border-radius: 5px;
    color: #ddd;
    border: none;
  }

  .Selected-delete-button:hover{
    background-color: red;
  }


  .hiscustom-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .hiscustom-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .hiscustom-popup-content h3 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .hiscustom-popup-content p {
    margin: 10px 0;
    color: #555;
  }
  
  .hiscustom-data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 14px;
  }
  
  .hiscustom-data-table th,
  .hiscustom-data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .hiscustom-data-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .hiscustom-popup-close {
    display: block;
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .hiscustom-popup-close:hover {
    background: #0056b3;
  }
  
  


  /* Responsive adjustments for small screens */
  @media (max-width: 768px) {
    .history-filter-controls {
      flex-direction: column;
      gap: 10px;
    }
  
    .history-filter-controls input[type="date"],
    .history-filter-controls select,
    .history-filter-controls input[type="text"] {
      width: 100%; /* Full width for small screens */
    }
  }
  