.dashboardmain {
  margin-top: 70px;
  padding: 0px 10px;
  padding-bottom: 10px;
}

.dashboardmain__wrapper{
  min-height: calc(100vh - 80px);
  padding: 30px;
background-color: white;
}

.dashboard {
  margin-top: 70px;
  padding: 0px 10px;
  padding-bottom: 10px;
}

.ongoing__title {
  margin-top: 50px;
  font-size: 1.0rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}


.single__card {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.single__card:hover {
  transform: scale(1.05);
}

.single__card:nth-child(1) {
  background: var(--card-01-bg);
}

.single__card:nth-child(2) {
  background: var(--card-02-bg);
}

.single__card:nth-child(3) {
  background: var(--card-03-bg);
}

.single__card:nth-child(4) {
  background: var(--card-04-bg);
}
.single__card:nth-child(5) {
  background: var(--card-05-bg);
}

.card__content h4 {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 400;
}

.card__content span {
  color: var(--heading-color);
  font-size: 2rem;
}

.card__icon {
  font-size: 2rem;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.845);
}

.dashboard__cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2rem;
}

.statics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  margin-top: 2rem;
}

.stats {
  background: var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  height: 320px;
  padding-bottom: 50px;
}

.tooltip__style {
  background: var(--body-bg) !important;
  color: #fff;
}

.stats__title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.recommend__cars-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-top: 2rem;
  column-gap: 2rem;
}

.recommend__car-card {
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}
.recommend__car-card:hover {
  transform: scale(1.05);
}

.recommend__car-card:nth-child(3n + 1) {
  background: var(--recommend-car01-bg);
}
.recommend__car-card:nth-child(3n + 2) {
  background: var(--recommend-car02-bg);
}
.recommend__car-card:nth-child(3n + 3) {
  background: var(--recommend-car03-bg);
}

.recommend__car-img{

  object-fit: cover;
}


.recommend__car-top h5 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 0.8rem;
  /* color: var(--body-bg); */
  margin-bottom: 10px;
  font-weight: 300;
}

.recommend__car-top h5 span i {
  font-size: 1.2rem;
}


.recommend__car-bottom h4 {
  font-size: 1.3rem;
  margin: 10px 0px;
}

.recommend__car-bottomflex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommend__car-bottomflex h4 span{
  font-size: 0.8rem;
}
.recommend__car-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recommend__icons {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.recommend__icons p:nth-child(1) {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.recommendfontsize{
  font-size: 0.9rem;
}

.spinnerAdjust1{
width: 80vw;
}

.waitingspinner {
  margin-top: 22%;
  width: 130px;
  height: 130px;
  background-image: url('../assets/images/carload.gif'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.waitingspinner2 {
  margin-top: 11%;
  width: 130px;
  height: 130px;
  background-image: url('../assets/images/carload.gif'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.waitingspinner3 {
  margin-top: 22%;
  width: 130px;
  height: 130px;
  background-image: url('../assets/images/carload.gif'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.loadingCircularspinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}



/* Chart Section */
.chart__section {
  height: 400px;
  display: flex;
  gap: 20px;
  margin: 50px 0px;
}

.chart1,
.chart2 {
  flex: 1;
  height: 100%; /* Ensures it adjusts to parent height */
  max-height: 400px; /* Fixed height */
  overflow: hidden; /* Prevent overflow of content */
  border: 1px solid rgba(107, 107, 107, 0.13);
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Ensures content flows vertically */
}

.staticstitle {
  padding: 10px;
  font-size: 1rem;
  color: var(--primary-color);
  text-align: start; /* Centers the title */
  margin: 0; /* Removes any default margin */
}

.chart__content {
  flex: 1; /* Allows the chart to fill available space */
  max-height: 100%; /* Keeps content within bounds */
  overflow: auto; /* Adds scrollbars if content overflows */
}

.chart__wrapper {
  height: 100%; 
  max-height: 400px; 
  min-height: 300px;
  display: flex;
  flex-grow: 1; 
  overflow: hidden; 
  padding: 10px;
  background: #fff; 
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

